'use strict'

import Polyfills from './Polyfills.js'
import ServingSizeCalculator from './ServingSizeCalculator.js'
import SuggestSearch from './SuggestSearch.js'
import { Bartender } from '@fokke-/bartender.js'
import boringmenu from '@teppokoivula/boringmenu'
import Glightbox from 'glightbox'

/**
 * Site class contains general purpose site-specific features.
 *
 * @version 1.1.0
 */
export default class Site {
	/**
	 * Class constructor
	 *
	 * @param {Object} options Options for the class.
	 */
	constructor(options = {}) {
		this.options = {}

		// Init polyfills
		const polyfills = new Polyfills()
		polyfills.init()

		// Init Bartender after mobile menu is ready
		document.addEventListener('boringmenu-init-done', () => {
			window.bartender = new Bartender({
				el: '.bartender-main',
				contentEl: '.bartender-content',
				debug: false,
				trapFocus: true,
				overlay: true,
			})

			window.bartender.addBar('bartender-right', {
				el: '.bartender-right',
				position: 'left',
				mode: 'float',
			})

			document.querySelector('.bartender-open').addEventListener('click', (event) => {
				window.bartender.toggle('bartender-right', event.target)
			})

			document.querySelector('.bartender-close').addEventListener('click', () => {
				window.bartender.close()
			})

			document.querySelectorAll('.bartender-bar.js-hide').forEach(bar => {
				bar.classList.remove('js-hide')
			})
		})

		// Init mobile menu
		const mobileMenu = document.getElementById('mobile-menu')
		new boringmenu({
			selectors: {
				menu: '.menu-mobile__list--level-1',
			},
			classes: {
				item: 'menu-mobile__item',
				itemActive: 'menu-mobile__item--current',
				itemParent: 'menu-mobile__item--parent',
				toggle: 'menu-mobile__toggle',
				toggleTextContainer: 'sr-only',
			},
			labels: {
				'menu.open': mobileMenu
					? mobileMenu.getAttribute('data-labels-open')
					: 'open',
				'menu.close': mobileMenu
					? mobileMenu.getAttribute('data-labels-close')
					: 'close',
			},
			icons: {
				'menu.open': 'icon-open',
				'menu.close': 'icon-close',
			},
		})

		// Initialize
		this.init(options)
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @param {Object} options Options for the class.
	 * @return {Object}
	 */
	init(options = {}) {
		// Merge user options to the defaults
		this.options = {
			responsiveTables: {
				selector: 'main table',
			},
			imageLinks: {
				parentSelector: 'main',
			},
			...options,
		}

		// Call individual init methods
		this.initResponsiveTables()
		this.initSkipLinks()
		this.initImageLinks()
		this.initHamburgers()
		this.initTopMenu()
		this.initAnchorLinks()
		this.initSmoothScroll()
		this.initExternalLinks()
		this.initAccordions()
		this.initServingSizeCalculator()
		this.initFilterForm()
		this.initSuggestSearch()
		this.initTagsShowMore()
		this.initReactionButtonsFeedback()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('site-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize responsive tables
	 *
	 * Finds content tables and wraps them with div.table-wrapper.
	 */
	initResponsiveTables() {
		document
			.querySelectorAll(this.options.responsiveTables.selector)
			.forEach((table) => {
				if (!table.closest('.table-wrapper')) {
					const tableWrapper = document.createElement('div')
					tableWrapper.classList.add('table-wrapper')
					tableWrapper.classList.add('overflow-x-auto')
					table.parentNode.insertBefore(tableWrapper, table)
					tableWrapper.appendChild(table)
				}
			})
	}

	/**
	 * Initialize skip links
	 *
	 * Finds skip links and enhances their behaviour for various screen readers and mobile devices.
	 */
	initSkipLinks() {
		const skipLinks = document.querySelectorAll(
			'.skip-link:not([data-skip-link])'
		)
		if (skipLinks.length) {
			const skipToBlur = (event) => {
				if (event.target.getAttribute('data-tabindex')) {
					event.target.removeAttribute('tabindex')
					event.target.removeAttribute('data-tabindex')
				}
			}
			skipLinks.forEach((skipLink) => {
				skipLink.setAttribute('data-skip-link', true)
				skipLink.addEventListener('click', (event) => {
					const skipTo = document.getElementById(
						event.target.href.split('#')[1]
					)
					if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
						event.preventDefault()
						skipTo.setAttribute('tabindex', '-1')
						skipTo.setAttribute('data-tabindex', true)
						skipTo.addEventListener('blur', skipToBlur)
						skipTo.addEventListener('focusout', skipToBlur)
						skipTo.focus()
					}
				})
			})
		}
	}

	/**
	 * Initialize image links
	 */
	initImageLinks() {
		// Parent of image links
		let parentNode = document.querySelector(
			this.options.imageLinks.parentSelector
		)
		if (!parentNode) return

		// Add glightbox class to image links
		parentNode
			.querySelectorAll(
				'a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]'
			)
			.forEach((link) => {
				if (!link.classList.contains('glightbox')) {
					if (
						!link.getAttribute('data-title') &&
						!link.getAttribute('data-glightbox')
					) {
						let figcaption = link.parentNode.querySelector('figcaption')
						if (figcaption) {
							let caption = figcaption ? figcaption.textContent : ''
							link.setAttribute('data-title', caption)
						}
					}
					link.classList.add('glightbox')
				}
			})

		// Initialize GLightbox
		if (parentNode.querySelector('.glightbox')) {
			window.glightbox = Glightbox()
		}
	}

	/**
	 * Initialize Hamburgers
	 */
	initHamburgers() {

		const hamburger = document.querySelector('.hamburger')
		if (!hamburger) return

		window.addEventListener('bartender-bar-before-open', () => {
			hamburger.classList.add('is-active')
		})

		window.addEventListener('bartender-bar-before-close', () => {
			hamburger.classList.remove('is-active')
		})
	}

	/**
	 * Initialize top menu
	 */
	initTopMenu() {

		if (!document.querySelector('.js-top-menu-toggle')) return

		document.querySelectorAll('.js-top-menu-toggle').forEach((toggle) => {
			toggle.addEventListener('click', event => {
				event.preventDefault()
				if (toggle.getAttribute('aria-expanded') === 'true') {
					toggle.setAttribute('aria-expanded', 'false')
					toggle.parentNode.classList.remove('top-menu__list-item--open')
				} else {
					document.querySelectorAll('.js-top-menu-toggle[aria-expanded="true"]').forEach((expandedToggle) => {
						expandedToggle.setAttribute('aria-expanded', 'false')
					})
					toggle.setAttribute('aria-expanded', 'true')
					toggle.parentNode.classList.add('top-menu__list-item--open')
					toggle.nextElementSibling.classList.remove('top-menu__list--overflow')
					toggle.nextElementSibling.focus()
				}
			})
		})

		document.addEventListener('keydown', event => {
			if (event.key === 'Escape') {
				document.querySelectorAll('.js-top-menu-toggle[aria-expanded="true"]').forEach((expandedToggle) => {
					expandedToggle.setAttribute('aria-expanded', 'false')
				})
			}
		})

		document.addEventListener('click', event => {
			if (event.target.closest('.top-menu') === null) {
				document.querySelectorAll('.js-top-menu-toggle[aria-expanded="true"]').forEach((expandedToggle) => {
					expandedToggle.setAttribute('aria-expanded', 'false')
				})
			}
		})

		// make sure that the top menu doesn't overflow the viewport due to inner submenus
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (!entry.isIntersecting) {
					// are we overflowing from right or left side?
					if (entry.boundingClientRect.right > window.innerWidth) {
						entry.target.classList.add('top-menu__list--overflow')
					} else if (entry.boundingClientRect.left < 0) {
						entry.target.classList.remove('top-menu__list--overflow')
					}
				}
			})
		}, {
			// observe with respect to the viewport
			root: null,
			rootMargin: '0px',
			// item is considered intersecting when it is completely within the viewport
			threshold: 1.0,
		})
		const submenuItems = document.querySelectorAll('.top-menu__list-item .top-menu__list')
		submenuItems.forEach(submenu => {
			observer.observe(submenu)
		})
	}

	/**
	 * Initialize anchor links
	 *
	 * Add js-smooth-scroll class to anchor links to enable smooth scrolling and so that scroll position can be adjusted for fixed headers.
	 */
	initAnchorLinks() {

		const anchorLinks = document.querySelectorAll('a[href^="#"]:not([href="#"]):not(.js-smooth-scroll)')
		if (!anchorLinks.length) return

		anchorLinks.forEach(link => {
			link.classList.add('js-smooth-scroll')
		})
	}

	/**
	 * Initialize smooth scroll
	 */
	initSmoothScroll() {

		const smoothScroll = document.querySelectorAll('.js-smooth-scroll')
		if (!smoothScroll.length) return

		const contentArea = document.querySelector('.bartender-content') || null

		let adjustTop = 0
		if (document.getElementById('adminbar')) {
			adjustTop = document.getElementById('adminbar').offsetHeight
		}
		if (document.querySelector('.top-menu')) {
			adjustTop += document.querySelector('.top-menu').offsetHeight
		}

		smoothScroll.forEach(el => {
			el.addEventListener('click', event => {
				event.preventDefault()
				const target = document.querySelector(el.getAttribute('href'))
				if (target) {
					if (el.getAttribute('href').indexOf('#') === 0) {
						history.pushState({}, '', el.getAttribute('href'))
					}
					contentArea.scrollTo({
						top: target.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - adjustTop,
						behavior: 'smooth',
					})
				}
			})
		})
	}

	/**
	 * Initialize external links
	 */
	initExternalLinks() {

		const externalLinks = document.querySelectorAll('a[href^="http"]:not([href*="' + window.location.hostname + '"]), a.external')
		if (!externalLinks.length) return

		externalLinks.forEach(link => {
			link.setAttribute('rel', 'noopener noreferrer')
			link.classList.add('js-external')
			if (link.getAttribute('target') === '_blank') {
				link.innerHTML += '<span class="sr-only"> (' + siteConfig.i18n.opens_in_new_window + ')</span>'
			} else {
				link.innerHTML += '<span class="sr-only"> (' + siteConfig.i18n.goes_to_external_site + ')</span>'
			}
		})
	}

	/**
	 * Initialize accordions
	 */
	initAccordions () {

		// Find accordion headers
		const accordionHeaders = document.querySelectorAll('[data-accordion-header]')
		if (!accordionHeaders.length) return

		// Add expand event to accordion buttons
		Array.prototype.forEach.call(accordionHeaders, accordionHeader => {
			let target = document.getElementById(accordionHeader.getAttribute('aria-controls'))
			if (target) {
				accordionHeader.onclick = () => {
					let expanded = accordionHeader.getAttribute('aria-expanded') == 'true' || false
					accordionHeader.setAttribute('aria-expanded', !expanded)
					target.hidden = expanded
				}
			}
		})
	}

	/**
	 * Initialize serving size calculator
	 */
	initServingSizeCalculator() {
		const servingSizeCalculator = document.querySelector('.serving-size-calculator')
		if (servingSizeCalculator) {
			new ServingSizeCalculator()
		}
	}

	/**
	 * Initialize filter form
	 */
	initFilterForm() {

		const filterForm = document.querySelector('.js-filter-form')
		if (!filterForm) return

		let filterFormTimeout
		let latestSearchURL = ''

		const filterFormCache = {}

		filterForm.addEventListener('submit', event => {

			event.preventDefault()

			const data = new FormData(filterForm)
			const resultsContainer = document.querySelector('.js-filter-form-results')
			const query = new URLSearchParams(data).toString().split('&').filter(param => param.split('=')[1] !== '' && param.split('=')[1] !== '0').join('&')
			const url = filterForm.getAttribute('action') + '?' + (query || 'q=')

			filterForm.setAttribute('data-url', url)

			if (filterForm.hasAttribute('disabled')) return

			clearTimeout(filterFormTimeout)
			filterFormTimeout = setTimeout(() => {

				if (filterForm.hasAttribute('disabled')) return
				filterForm.setAttribute('disabled', '')

				latestSearchURL = url

				// update URL
				history.pushState({}, '', latestSearchURL)

				// first check if we have results cached
				if (filterFormCache[latestSearchURL]) {

					// re-enable the form and submit again if filter values have changed
					filterForm.removeAttribute('disabled')
					if (filterForm.getAttribute('data-url') !== latestSearchURL) {
						filterForm.dispatchEvent(new CustomEvent('submit', {
							cancelable: true,
						}))
					}

					// update the search results
					resultsContainer.innerHTML = filterFormCache[latestSearchURL]
					resultsContainer.classList.remove('js-loading')
					return
				}

				// display loading animation
				resultsContainer.classList.add('js-loading')

				// fetch the search results
				// note: send xhr header to let backend know it's a xhr request
				fetch(latestSearchURL, {
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
					},
				})
					.then(response => response.text())
					.then(html => {

						// cache the results
						filterFormCache[latestSearchURL] = html

						// re-enable the form and submit again if filter values have changed
						filterForm.removeAttribute('disabled')
						if (filterForm.getAttribute('data-url') !== latestSearchURL) {
							filterForm.dispatchEvent(new CustomEvent('submit', {
								cancelable: true,
							}))
							return
						}

						// update the search results
						resultsContainer.innerHTML = html
						resultsContainer.classList.remove('js-loading')

						// if there are results, add event listener to the status message
						if (resultsContainer.querySelector('.js-filter-form-results-status')) {
							resultsContainer.querySelector('.js-filter-form-results-status').addEventListener('keydown', event => {
								this.maybeDisplayFilterFormResults(event)
							})
						}
					})
			}, event.detail && event.detail.input && event.detail.input.getAttribute('type') == 'search' ? 500 : 250)
		})

		// trigger the form submit on input change
		filterForm.querySelectorAll('input, select').forEach(input => {
			const changeEvent = input.getAttribute('type') == 'search' ? 'input' : 'change'
			input.addEventListener(changeEvent, () => {
				filterForm.dispatchEvent(new CustomEvent('submit', {
					cancelable: true,
					detail: {
						input: input,
					},
				}))
			})
		})

		// jump to the search results when enter key is pressed
		filterForm.addEventListener('keydown', event => {
			this.maybeDisplayFilterFormResults(event)
		})
	}

	/**
	 * Jump to the filter form search results when enter key is pressed
	 *
	 * @param {Object} event The event object
	 */
	maybeDisplayFilterFormResults(event) {
		if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {

			event.preventDefault()

			const contentArea = document.querySelector('.bartender-content') || null

			let adjustTop = 0
			if (document.getElementById('adminbar')) {
				adjustTop = document.getElementById('adminbar').offsetHeight
			}
			if (document.querySelector('.top-menu')) {
				adjustTop += document.querySelector('.top-menu').offsetHeight
			}

			const target = document.querySelector('.js-filter-form-results')
			if (target) {
				contentArea.scrollTo({
					top: target.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - adjustTop,
					behavior: 'smooth',
				})
			}

			document.querySelector('.js-filter-form-results ul').focus()
		}
	}

	/**
	 * Initialize suggest search
	 */
	initSuggestSearch() {
		if (document.querySelector('.js-suggest-search')) {
			const suggestSearch = new SuggestSearch()
			suggestSearch.init()
		}
	}

	/**
	 * Initialize tags show more feature
	 */
	initTagsShowMore () {

		const tagsLists = document.querySelectorAll('.js-tags-list')
		if (!tagsLists.length) return

		document.querySelectorAll('.js-tags-item.js-hide').forEach(tag => {
			if (tag.querySelector('input').checked) {
				tag.classList.remove('js-hide')
				return
			}
			tag.classList.add('opacity-0')
		})

		document.addEventListener('click', event => {

			if (!event.target.classList.contains('js-tags-show-more')) return

			event.preventDefault()

			let tagsList = event.target.closest('.js-tags-list')
			let tagsToShow = tagsList.getAttribute('data-tags-to-show')

			for (const tag of tagsList.querySelectorAll('.js-tags-item.js-hide')) {
				tag.classList.remove('js-hide')
				window.setTimeout(() => tag.classList.remove('opacity-0'), 100)
				--tagsToShow
				if (tagsToShow == 0) break
			}

			event.target.blur()

			if (tagsToShow <= 0) {
				event.target.setAttribute('hidden', '')
			}
		})
	}

	/**
	 * Initialize reaction buttons feedback
	 */
	initReactionButtonsFeedback () {
		const reactionButtonsFeedback = document.querySelector('.js-reaction-buttons-feedback')
		if (reactionButtonsFeedback) {
			document.addEventListener('reactions-updated', event => {
				const activeButton = document.querySelector('.reaction-buttons__button--active')
				if (!activeButton.getAttribute('data-feedback-form')) {
					reactionButtonsFeedback.setAttribute('hidden', '')
				} else if (activeButton.getAttribute('data-feedback-form') && reactionButtonsFeedback.hasAttribute('hidden')) {
					reactionButtonsFeedback.removeAttribute('hidden')
				} else {
					// make sure that the iframe embed is refreshed
					const iframe = reactionButtonsFeedback.querySelector('iframe')
					if (iframe) {
						iframe.src += ''
					}
				}
			})
		}
	}
}
